.container-target {
  width: 100%;
  height: fit-content;
  background-color: #F6F7FA;
  padding: 25px;
}

.tab-header {
  padding-top: 0px;
}

.target-total {
  margin-top: 50px;
}

.target-unit {
  margin-top: 25px;
}

.target-total .input-total,
.target-unit .input-unit {
  background-color: white;
  padding: 30px 40px;
  display: flex;
}

.block-input {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0 20px;
}

.block-input input {
  background-color: #F5F8FA;
  height: 40px;
}

.col-10 {
  width: 83.3333333333%;
}

.col-2 {
  width: 16.6666666667%;
  padding: 0 10px;
}


.d-flex {
  display: flex;
}

.d-grid-50 {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
}

.d-grid-33 {
  display: grid;
  width: 100%;
  grid-template-columns: 33.33333% 33.333333333% 33.333333333%;
}

.d-grid-99 {
  display: grid;
  width: 100%;
  grid-template-columns: 99.99999966666%;
}

.d-grid-100 {
  display: grid;
  grid-template-columns: 100%;
}

.buttons {
  text-align: center;
}

.button:hover {
  opacity: 0.7;
  transition: opacity 0.5s;
}

.btn-create {
  margin-top: 15px;
  padding: 0px 40px;
  background-color: #5f89ba;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  margin-left: 5%;
  cursor: pointer;
  height: 40px;
}

.btn-cancel {
  margin-top: 15px;
  padding: 0px 40px;
  margin-left: 1%;
  border-radius: 5px;
  background-color: #ffffff !important;
  color: #5f89ba !important;
  border: solid 1px #5f89ba !important;
  height: 40px;
}

.align-center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-left {
  border-left: rgba(95, 137, 186, 0.50) 1px solid;
  ;
}

.border-top-block {
  border-top: rgba(95, 137, 186, 0.50) 1px solid;
  ;
}

.header-block-target {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.header-sub-block-target {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.label-input {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.label-total-input {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-total-in-unit {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}

* {
  scroll-behavior: smooth
}