.filter-renevue {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.select-year {
  background-color: #ffffff;
}

.next-month {
  justify-content: space-between;
  display: flex;
  font-size: 10px;
  color: #2e89ff;
  width: calc(100% - 350px);
  font-family: Inter;
  float: right;
  position: relative;
  top: 20px;
  z-index: 98;
}

.container-report-order {
  width: 100%;
  height: fit-content;
  background-color: #f6f7fa;
  padding: 5px 25px 25px 25px;
}

.order-renevue-area {
  position: relative;
  width: 100%;
}

.order-renevue-container {
  width: calc(100% - 350px) !important;
  overflow-x: auto;
  margin-left: 350px;
  overflow-y: visible;
  padding-bottom: 1px;
}

.header-order-renevue {
  position: sticky;
  left: 0;
  width: 100%;
}

.row-invoice-revenue {
  background-color: #f6f7fa !important;
  border: none !important;
  padding-left: 0px !important;
}

.h-37 {
  height: 37px !important;
}
.fs-11{
  font-size: 11px !important;
}
.w-350 {
  width: 350px !important;
}
.w-250 {
  width: 250px !important;
}
.mt-15 {
  margin-top: 15px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.fw-bold {
  font-weight: bold !important;
}

#table-renevue-month {
  border-collapse: separate;
  box-shadow: none;
}

#table-renevue-month td::after,
#table-renevue-month th::after {
  content: "";
  position: absolute;
  height: 1px;
  background: #dbdada;
  top: 100%;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

#table-renevue-month td,
#table-renevue-month th {
  border-bottom: none;
  border-right: 3px solid #f6f7fa;
  padding: 0px 10px;
  border-bottom: 1px solid #ffffff;
  position: relative;
}

#table-renevue-month tr:nth-child(3) td::after,
#table-renevue-month tr:nth-child(3) th::after {
  height: 0px;
}

#table-renevue-month tr:nth-child(6) td::after,
#table-renevue-month tr:nth-child(6) th::after {
  height: 0px;
}

#table-renevue-month hr {
  margin-top: 5px;
  display: none;
}

#table-renevue-month .order-renevue-first-column {
  width: 150px !important;
  position: absolute;
  left: 0;
  background: white;
  z-index: 1;
  height: 35px;
  padding-top: 6px;
}

#table-renevue-month .order-renevue-second-column {
  width: 200px !important;
  position: absolute;
  left: 150px;
  background: white;
  z-index: 1;
  font-weight: bold;
  height: 35px;
  padding-top: 6px;
}

.data-table {
  background: white;
}

.border-bottom {
  border-bottom: 1px solid #0004ff;
  position: absolute;
  top: 70%;
  bottom: 0;
}

.order-report-area {
  /* margin-top: 5px; */
}

.order-report-container {
  /* overflow: auto;
  height: 900px; */
  width: 100%;
}

.order-report-container #table-order-report {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

.order-report-container th,
.order-report-container td {
  padding: 0px 5px;
  font-size: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.order-report-container td {
  color: #000;
}
.order-report-container tr {
  height: 25px;
}

#table-order-report .first-column {
  position: sticky;
  left: 0;
  z-index: 1;
  width: 350px;
  max-width: 350px !important;
  border: 1px solid rgba(95, 137, 186, 0.5);
}

#table-order-report .second-column {
  position: sticky;
  left: 250px;
  z-index: 1;
  min-width: 100px;
  max-width: 100px !important;
  width: 100px !important;
  border-right: 1px solid rgba(95, 137, 186, 0.5);
}

/* #table-order-report thead {
  position: sticky;
  top: 0;
  z-index: 2;
} */

#table-order-report tr:last-child th {
  /* position: sticky; */
  /* top: 0; */
  background: #e4e5ee !important;
  border-top: 1px solid rgba(95, 137, 186, 0.5);
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
}

#table-order-report tr:last-child th,
#table-order-report td {
  border-top: 1px solid rgba(95, 137, 186, 0.5);
  border-bottom: 1px solid rgba(95, 137, 186, 0.5);
  background-color: #ffffff;
}

#table-order-report th:first-child {
  z-index: 3;
}

#table-order-report tr:last-child th:after,
#table-order-report th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

#table-order-report tr:last-child th:before {
  top: -1px;
  border-top: 1px solid rgba(95, 137, 186, 0.5);
}

#table-order-report tr:last-child th:after {
  bottom: -1px;
  border-bottom: 1px solid rgba(95, 137, 186, 0.5);
}

#table-order-report .total-order-header {
  font-weight: 700 !important;
  position: "sticky";
  right: 0;
  z-index: 2;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#table-order-report .total-order {
  font-weight: 700 !important;
  position: "sticky";
  right: 0;
  z-index: 1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.invoice-detail {
  width: 100%;
}

.invoice-detail:last-child hr:last-child {
  display: none;
}

#demo-positioned-menu {
  width: 250px;
  word-break: break-word;
}

#demo-positioned-menu .divider-invoice {
  margin: 8px 20px;
}

.order_name {
  color: #0063b0 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.invoice_label {
  color: #000 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  margin: 8px 0px !important;
}

.invoice_number {
  color: #000 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.invoice_note {
  white-space: pre-line !important;
  padding-right: 10px !important;
  margin: 8px 0px !important;
  word-break: break-word;
}

.row-renevue th {
  border-bottom: 1px solid #ffffff;
}

.cell-divider {
  border-left: none !important;
  border-top: 1px solid #ffffff !important;
  border-right: 1px solid #e0dfdf !important;
  border-bottom: 1px solid #ffffff !important;
  padding: 5px 10px !important;
}

.border-right {
  border-right: 1px solid #e0dfdf !important;
}

.border-top {
  border-left: none !important;
  border-top: 1px solid #ffffff !important;
  border-right: 1px solid #e0dfdf !important;
  border-bottom: 1px solid #ffffff !important;
}

.cell-divider::after {
  content: "";
  position: absolute;
  height: 1px;
  background: #dbdada;
  top: 100%;
  width: calc(100% - 20px);
  left: 50%;
  transform: translateX(-50%);
}

#table-order-report .order-revenue.revenue_per_contact th.cell-divider::after,
#table-order-report .revenue-distribution.percent_target th.cell-divider::after,
#table-order-report .invoice-revenue.actual_renevue th.cell-divider::after {
  background: #ffffff;
}

#table-order-report tr th:nth-child(13),
#table-order-report tr th:nth-child(14) {
  border-right: none !important;
}
.align-items-center {
  align-items: center;
}
.orderRevenue {
  font-family: Inter;
  font-size: 10px;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}
.no-scroll-y {
  overflow-x: visible !important;
  /* overflow-y: scroll; */
}

.datepicker-report-order {
  background: none !important;
}

.datepicker-report-order fieldset {
  border: none !important;
}

.datepicker-report-order input {
  width: 40px;
  padding: 0px;
  color: rgb(95, 137, 186);
  font-weight: 700;
}

.datepicker-report-order button {
  padding: 0px;
  margin-left: -10px;
}

.datepicker-report-order svg {
  color: rgb(95, 137, 186);
  font-size: 0.5em;
}

.search-report-order {
  min-height: 30px;
}

.filter-report-order {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}

.filter-report-order .dropdown div {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.div-filter {
  max-width: calc(100vw - 30%);
}

.data-number, .data-date{
  text-align: right !important;
}
#table-order-report .invoice_overview{
  position:absolute;
  bottom: 5px;
  right:5px;
  font-weight: 700;
}

#table-order-report .contact-name{
  position:absolute;
  top: 9px;
  left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 240px;
  font-style: oblique;
  text-align: start;
}

#table-order-report .order-information .order-name{
  text-align: start;
  word-break: break-word;
}
#table-order-report .order-header {
  display: table-cell;
  position:relative;
  margin-bottom: 15px; 
  height: 75px;
  min-width: 250px;
  padding-bottom: 20px;
  padding-top: 30px;
}

.revenue-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.export-button {
  display: flex;
  justify-content: space-between;
  background-color: #5179a7; 
  color: #fff; 
  font-weight: bold;
  padding: 5px 20px 5px 10px; 
  align-items: center;
  border-radius: 5px; 
  border-width: 1px; 
  cursor: pointer;  
  width: fit-content;
}

.export-button:hover {
  background-color: rgb(48, 87, 131); 
  font-weight: bolder;
}

.link-order-navigate {
  color: #3d3d3d !important;
}