* {
    font-family: 'Inter';
}
.table {
    width: -webkit-fill-available;
    border-collapse: separate;
    border-spacing: 0;
}

.table-header {
    background-color:#E4E5EE;
    text-align: left;
    padding: 8px;
}

.table td,
.table th {
    padding: 5px;
}

.table th {
    background-color: #E4E5EE;
}

.table td, .table th {
    border-bottom: 1px solid #5F89BA80;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
}

.table thead td, .table thead th {
    border-top: 1px solid #5F89BA80;
}

.sticky-table table thead {
    position: sticky;
    top: 0;
    z-index: 2;
}

thead tr {
    height: 37px;
}

tbody tr {
    height: 35px;
}

.table-cell {
    text-align: left;
}

.checkboxTable {
    text-align: center;
}

.checkboxRow {
    width: 30px;
}

.table-container {
    overflow-x: auto;
    margin: 20px 0px;
}

.table-container.sticky-table {
    overflow-y: auto;
    max-height: calc(100vh - 90px);
    position: relative;
}

.small {
    width: 120px;  
}

.table-cell:not(:empty):hover, p.hover-pointer:hover {
    color: #2E89FF;
}
  
.hover-pointer {
    cursor: pointer;;
}

.hover-default {
    cursor: default;
    color: inherit;
}

a {
    color: #2E89FF !important;
}

.hover-default:hover {
    color: inherit !important;
}

.table .pic-avatar {
    min-width: 25px;
    max-width: 25px;
    width: 25%;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 5px;
}

.table .text-click {
    color: #2E89FF;
    cursor: pointer;
    width: fit-content;
}

.table .text-click:hover, .table a:hover {
    opacity: 0.5;
}

.table .iconEdit {
    cursor: pointer;
}

.table .iconEdit:hover {
    opacity: 0.5;
    transition: ease-in 0.1s;
}

.test {
    color: rgb(95, 137, 186);
    margin: 0 -.25rem;
    padding: 0 .25rem;
    cursor: pointer;
}

div .test:hover {
   opacity: 0.5;
}

.statusInvoice{
    width: 150px;
    min-width: 150px;
}

.dashboard-td {
    max-width: 120px !important;
}

.fixTableHead {
    overflow-y: auto;
    height: 350px;
}
.fixTableHead thead th {
    position: sticky;
    top: 0;
}

.tax {
    width: 100%;
    justify-content: end;
}

.tax-percent {
    min-width: 60px;
    margin-right: 15px;
}
table .text-right{
    text-align: right;
}

table .text-center{
    text-align: center;
}