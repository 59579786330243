.pop-up-image .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    z-index: 999;
}

.pop-up-image .close:hover,
.pop-up-image .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}