.tabs {
    display: flex;
    padding: 20px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}

.tab {
    padding: 10px 10px 10px 0px;
    color: #727272;
    cursor: pointer;
    text-align: left;
    border-bottom: 1px solid #727272;
}

.selected {
    color: #5F89BA;
    border-color: #5F89BA;
    border-bottom-width: 2px;
}

.selected-tab:hover {
    color: #5F89BA;
    border-color: #5F89BA;
    border-bottom-width: 2px;
}

.tab-container {
    margin: 25px 25px;
}

.bread-crumb {
    font-weight: bold;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.bread-crumb__link:hover {
  cursor: pointer;
}

thead {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
}

tbody {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}

input:hover {
    background-color: #5F89BA;
}

.tab:hover {
    color: #5F89BA;
    border-color: #5F89BA;
    border-bottom-width: 2px;
}

.d-flex {
    display: flex;
}

.fields-search {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1%;
}

.search-field {
    margin-left: auto;
    margin-top: 5px;
    width: 700px;
    max-width: calc(100vw - 50%);
}

input[type="text"] {
    border: 1px solid #5F89BA;
    border-radius: 4px;
    height: 30px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
}

.contact-detail-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 25px;
    width: 26%;
    height: 100%;
    border-right: solid 0.8px #5F89BA;
  }

  .contact-detail-container .bread-crumb {
    margin-bottom: 40px;
  }

  .bread-crumb:hover {
    cursor: default;
  }
  
  .contact-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .contact-info-item {
    margin-bottom: 1em;
    font-size: 14px;
  }
  
  .contact-info-label {
    font-weight: 500;
    color: #80868B;
  }

  .blue-text {
    color: #5F89BA !important;
  }

  .activities-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 25px;
    width: 48%;
    border-right: solid 0.8px #5F89BA;
    background-color: #F6F7FA;
  }

  .bread-crumb .blue-text:not(.add-contact-point-button) {
    font-size: 20px !important;
  }

  .detail-container {
    height: 100%;
  }

  .contact-point-block {
    width: 26%;
    flex-direction: column;
  }

  .detail-container .bread-crumb {
    font-size: 14px !important; 
  }

  /* .contact-point {
    padding: 25px 15px;
    min-height: 25%;
    max-height: 36%;
    border-bottom: solid 0.8px #5F89BA;
    overflow-y: auto;
  } */

  .contact-point {
    padding: 0px 15px;
    min-height: 25%;
    border-bottom: solid 0.8px #5F89BA;
    overflow-y: auto;
  }
  
  .contact-point-content {
    margin-bottom: 20px;
  }

  .contact-point:last-child {
    flex-grow: 1;
    margin-bottom: 20px;
    max-height: initial !important;
  }

.add-contact-point-button:hover {
    cursor: pointer;
    margin-right: 0;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0px;
  font-size: 14px;  
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.filter-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filter .tab {
  color: #5F89BA;
  margin-right: 25px;
  margin-left: 0px !important;
  border-bottom: none;
}

.filter .tab:last-child {
  margin-right: 0px;
}

.filter p {
  position: relative;
  bottom: 3px;
  left: 2px;
}

.action {
  position: relative;
}

w-20 {
  width: 20%;
}

.search-field {
  position: relative;
}

.search-field input {
  padding-right: 2rem;
}

.search-input input {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 30px;
  color: white;
}

.search-input input:hover {
  color: white ;
}

.input-black{
  color: #000000 !important;
}

.search-field svg {
  position: absolute;
  top: 15px;
  right: 0.5rem;
  transform: translateY(-50%);
}

.tag {
  display: inline-block;
  padding: 0px 7px;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 13px;
  border-radius: 20px;
  color: #000000;
  background: linear-gradient(to right, #dce7f3, #5F89BA);
  padding-right: 18px;
}

.tag-stages, .tag-units .tag-lists, .tag-createdate, .tag-lastupdate, .tag-text, .tag-types, .tag-result {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: -webkit-fill-available;
}

.tag-remove-button {
  margin-left: 5px;
  position: absolute;
  right: 6px;
}

.header-items {
  display: flex;
}

.pagination-block {
  margin-left: auto;
  padding: 25px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0px !important;
}

.MuiSvgIcon-root:hover {
  cursor: pointer;
  color: #0E73E7;
}

@media screen and (max-width: 1050px) {
  .header-items {
    display: block;
  }

  .filter-container {
    justify-content: flex-start;
  }

  .pagination-block {
    margin-right: auto;
  }

  .filter {
    padding: 0px 0px;
  }
}

input:hover {
  background-color: inherit !important;
  color: inherit;
}

.to-bottom-35px {
  bottom: 2.2rem;
}

.bold {
  font-weight: bold;
}

.detail .btn-create {
  margin-top: 15px;
  padding: 0px 40px;
  background-color: #5f89ba;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  margin-left: 5%;
  cursor: pointer;
  height: 40px;
}

.detail .btn-create-contact-point {
  margin-top: 15px;
  padding: 0px 40px;
  background-color: #5f89ba;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  margin-left: 5%;
  cursor: pointer;
  height: 40px;
}

.detail .btn-cancel {
  margin-top: 15px;
  padding: 0px 40px;
  margin-left: 1%;
  border-radius: 5px;
  background-color: #ffffff !important;
  color: #5f89ba !important;
  border: solid 1px #5f89ba !important;
  height: 40px;
}

.detail {
  height: 100%;
}

.text-nowrap {
  white-space: nowrap!important;
}

.large-input {
  width: 100%;
}

.larger-input {
  width: 110% !important;
}

.textarea-autosize {
  width: 100%;
  resize: none;
  overflow: hidden;
}

.textarea-autosize:focus {
  padding: 0px 10px;
}

.contact-point .item-wrap {
  background-color: rgb(255, 255, 255);
  width: 100%;
  border: 0.8px solid rgb(95, 137, 186);
  padding: 10px;
  margin-top: 20px;
  border-radius: 3px;
}

.contact-point .contact-point-header {
  width: 100%;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 3;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-bottom: -5px;
}

.contact-point .info-contact p {
  word-wrap: break-word;
}

.color-33475b {
  color: #33475B;
}

.deal-name {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deal-skeleton {
  background-color: rgb(255, 255, 255);
  width: 100%;
  border: 0.8px solid rgba(0, 0, 0, 0.11);
  padding: 10px;
  margin-top: 20px;
  border-radius: 3px;
}

.contact-point-content p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.contact-point-content p:hover {
  cursor: default;
  white-space: normal;
}

.contact-point .info-contact p:hover {
  cursor: default;
}

.contact-point .info-contact p.blue-text.bold:hover {
  cursor: pointer;
}