.tabs {
    display: flex;
    padding: 20px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}

.tab {
    padding: 10px 10px 10px 0px;
    color: #727272;
    cursor: pointer;
    text-align: left;
    border-bottom: 1px solid #727272;
}

.selected {
    color: #5F89BA;
    border-color: #5F89BA;
    border-bottom-width: 2px;
}

.selected-tab:hover {
    color: #5F89BA;
    border-color: #5F89BA;
    border-bottom-width: 2px;
}

.tab-container {
    margin: 25px 25px;
}

.bread-crumb {
    font-weight: bold;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

thead {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
}

tbody {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}

input:hover {
    background-color: #5F89BA;
}

.tab:hover {
    color: #5F89BA;
    border-color: #5F89BA;
    border-bottom-width: 2px;
}

.d-flex {
    display: flex;
}

.search-field {
    margin-left: auto;
    margin-top: 5px;
    width: 700px;
}

input[type="text"] {
    border: 1px solid #5F89BA;
    border-radius: 4px;
    height: 30px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
}

.contact-detail-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 25px;
    width: 26%;
    border-right: solid 0.8px #5F89BA;
  }

  .contact-detail-container .bread-crumb {
    margin-bottom: 40px;
  }

  .bread-crumb:hover {
    cursor: default;
  }
  
  .contact-info-container {
    display: flex;
    flex-direction: column;
  }
  
  .contact-info-item {
    margin-bottom: 1em;
  }
  .contact-info-item .add-people {
    color: #0063B0;
  }

  .contact-info-item .add-people:hover {
    cursor: pointer;
    color: #5F89BA;
  }
  
  .contact-info-label {
    font-weight: 500;
    color: #80868B;
  }

  .blue-text {
    color: #0E73E7
  }

  .activities-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 25px;
    width: 48%;
    border-right: solid 0.8px #5F89BA;
    background-color: #F6F7FA;
  }

  .bread-crumb .blue-text:not(.add-contact-point-button) {
    font-size: 20px !important;
  }

  .detail-container {
    height: 100%;
  }

  .contact-point-block {
    width: 26%;
  }

  .detail-container .bread-crumb {
    font-size: 14px !important; 
  }

.add-contact-point-button:hover {
    cursor: pointer;
    margin-right: 0;
}

.filter {
  display: flex;
  padding: 20px 0px;
  font-size: 14px;  
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.filter .tab {
  color: #5F89BA;
  margin-left: 30px;
  border-bottom: none;
}

.filter p {
  position: relative;
  bottom: 3px;
  left: 2px;
}

.action {
  position: relative;
}

w-20 {
  width: 20%;
}

.search-field {
  position: relative;
}

.search-field input {
  padding-right: 2rem;
}

.search-input input {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 30px;
  color: white;
}

.search-input input:hover {
  color: white;
}

.input-black{
  color: #000000 !important;
}
.search-field svg {
  position: absolute;
  top: 15px;
  right: 0.5rem;
  transform: translateY(-50%);
}

.tag {
  display: inline-block;
  padding: 0px 7px;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 13px;
  border-radius: 20px;
  color: #000000;
  background: linear-gradient(to right, #dce7f3, #5F89BA);
  position: relative;
  bottom: 0px;
  padding-right: 18px;
}

.tag-stages, .tag-units, .tag-lists, .tag-text, .tag-types {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: -webkit-fill-available;
}

.tag-remove-button {
  margin-left: 5px;
  position: absolute;
  right: 6px;
}

.header-items {
  display: flex;
}

.pagination-block {
  margin-left: auto;
  padding: 25px 0px;
  margin-top: 5px;
}

.MuiSvgIcon-root:hover {
  cursor: pointer;
  color: #0E73E7;
}

@media screen and (max-width: 1050px) {
  .header-items {
    display: block;
  }

  .filter-container{
    margin-left: 0 !important;
  }

  .pagination-block {
    margin-right: auto;
    padding: 25px 0px;
    margin-top: 5px;
    text-align: end;
  }

  .filter {
    padding: 0px 0px;
  }
}

input:hover {
  background-color: inherit !important;
}

.to-bottom-35px {
  bottom: 2.2rem;
}

.bold {
  font-weight: bold;
}

.input-white{
  color: #ffffff;
}