.dropdown {
    position: relative;
    display: inline-block;
    user-select: none;
    margin-right: 10px;
  }
  
  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown-toggle.disabled, .dropdown-toggle:disabled {
    color: hsl(0, 0%, 60%);
  }

  .dropdown-toggle.disabled .MuiSvgIcon-root, .dropdown-toggle:disabled .MuiSvgIcon-root, .dropdown-toggle.disabled .MuiSvgIcon-root:hover, .dropdown-toggle:disabled .MuiSvgIcon-root:hover {
    color: hsl(0, 0%, 60%) !important;
    cursor: default !important;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    /* right: -0px; */
    left: -16px;
    z-index: 1000;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    max-height: 250px;
  }
  
  .dropdown-menu li {
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background-color: #e9ecef;
  }
  
  .dropdown .fixed {
    position: sticky;
    top: 0;
  }
  .dropdown-checkbox{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
  }
  .dropdown-checkbox input {
    margin-right: 5px;
  }

  .dropdown-checkbox label {
    position: relative;
    bottom: 2px;
    color: #474747 !important;  
  }

  .dropdown-checkbox input:checked + label {
    color: #5F89BA !important;
  }
  
  .dropdown-checkbox input, label {
    cursor: pointer;
  }
    .scroll-x {
      overflow-x: auto;
    }