.container-meeting {
    width: 100%;
    background-color: #EFEBF7;
    display: flex;
    justify-content: center;
}
.container-meeting .container-content{
    width: 40%;
    min-width: 400px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 100px;
}
.container-meeting .title {
    margin-top: 30px;
    padding-bottom: 20px;
    font-weight: bold;
}
.container-meeting .content {
    background-color: white;
    border-radius: 7px;
    padding: 10px 20px;
}
.container-meeting  .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.container-meeting  .line {
    margin: 10px 0;
    border-color: #B3C3DA;
}
.container-meeting  .footer {
    margin-top: 20px;
   display: flex;
   justify-content: space-between;
}
.container-meeting  .btn-left, .container-meeting .btn-right {
   display: flex;
}
.container-meeting .btn-create {
    margin-top: -5px;
    background-color: #5f89ba;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}
.container-meeting .btn-back {
    margin-top: -5px;
    background-color: #ffffff;
    color:  #5f89ba;
    border:  1px solid #5f89ba;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.container-meeting .form-group label {
    padding: 10px 0;
}
.container-meeting .container-date {
    display: flex;
    margin-bottom: 15px;
}
  
.container-meeting .form-group input[type="text"], .container-meeting .form-group input[type="number"] {
    width: 100%;
    padding: 18px 14px;
    font-size: 14px;
    border: 1px solid #b4c7de;
    border-radius: 5px;
}

.container-meeting .form-group textarea {
    padding: 10px 14px;
    font-size: 14px;
    border: 1px solid #b4c7de;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  .container-meeting .form-group input {
    margin-bottom: 5px;
  }