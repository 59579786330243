.selected-menu {
    color: red !important;
}

.css-19r6kue-MuiContainer-root {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.css-19r6kue-MuiContainer-root {
    max-width: none !important;
}

.nav-bar {
    padding-left: 14px;
}

.current-user:hover {
    border-radius: unset;
}
