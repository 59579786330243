.tag-contact,
.tag-deal,
.tag-orderDate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: -webkit-fill-available;
}

.header-items-invoice .filter .dropdown-menu,
.header-items-invoice .filter .daterangepicker {
    left: auto !important;
    right: 0;
}

.total-received-blue {
    color: #29C203;
}

.total-received-red {
    color: #FF0000;
}