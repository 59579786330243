.checkbox-pic {
    width: 150px;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.row-pic {
    width: 100%;
}

#modal-update-PIC .modal-body {
    overflow: inherit;
    padding-left: 50px;
}

.error-text-pic {
    color: red;
    position: relative;
    margin-left: 155px;
    margin-top: 0px !important;
}