.daterangepicker .fromDateTimeContainer .fromDateHourContainer .timeContainer {
  display: none !important;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-daterange .daterangepicker:before,
.contact-daterange .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: none;
}
