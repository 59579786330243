.modal-info .modal{ 
   max-width: 500px;
}
.modal-info .modal-header { 
    border-bottom: none;
}

.modal-info .modal-body {
    position: relative;
}

.modal-info .button-close {
    float: right;
    font-size: 18px;
    background: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 15px;
    color: #474747
}