.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
    border-radius: 0px !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    border-color: #ffffff;
}

.ck-content .image img{
    max-height: 150px;
}