.contact-list .filter-container {
  margin-left: 50% !important;
}

.back {
  font-size: 18px !important;
  margin-bottom: 5px;
  cursor: pointer;
}
.back:hover {
  opacity: 0.8;
}

.fields-search {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1%;
}

.header-items-list-detail .filter .dropdown-menu, .header-items-list-detail .filter .daterangepicker{
  left: auto !important;
  right: 0;
}

@media (min-width: 1536px) {
  .css-1ekb41w {
      max-width: none !important;
  }
}