.container-dashboard {
    width: 100%;
    height: fit-content;
    background-color: #F6F7FA;
    padding: 25px;
}

.container-dashboard .container-info {
    width: 100%;
    padding: 25px;
    border-radius: 4px;
    background: #FFFDFD;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 30px;
}

.container-dashboard .container-chart {
    width: 100%;
    height: 100vh;
    padding: 25px;
    border-radius: 4px;
    background: #FFFDFD;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
}

.container-dashboard .container-info .title-table {
    margin: 10px 10px 10px 10px;
}

.container-dashboard .container-info .container-content {
    display: flex;
    justify-content: space-between;
}

.container-dashboard .container-info .info-left, .container-dashboard .container-info .info-right {
    width: 48%;
    border-radius: 4px;
    background: #F6F7FA;
    box-shadow: -1px 3px 10px 0px rgba(0, 0, 0, 0.20);
}

.container-dashboard .container-info .content-table {
    max-height: 350px;
}

.container-dashboard .container-info .table-container {
    margin-top: 0;
}