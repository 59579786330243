.spinner {
    border: 3px solid #ffff;
    border-top-color: #3489FF;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .dot1,
  .dot2 {
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  
  .dot1 {
    left: 0;
    animation: bounce 0.6s infinite alternate;
  }
  
  .dot2 {
    left: 30px;
    animation: bounce 0.6s infinite alternate-reverse;
  }
  
  @keyframes bounce {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 9999;
  }

  