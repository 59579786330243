.dropdown {
  position: relative;
  display: inline-block;
  user-select: none;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: -16px;
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  max-height: 300px;
}

.dropdown-menu li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #e9ecef;
}

.dropdown-menu li.footFilter:hover {
  background-color: #fff;
}

.dropdown .fixed {
  position: sticky;
  top: 0;
}

.dropdown .fixed-bottom {
  position: sticky;
  bottom: 0;
}

.dropdown-filter input {
  margin-right: 5px;
}

.dropdown-filter label {
  position: relative;
  bottom: 2px;
  color: #474747 !important;
}

li.selected {
  background-color: #e9ecef;
}

.dropdown-filter input:checked + label {
  color: #5f89ba !important;
}

.hover:hover {
  color: #2e89ff;
  cursor: pointer;
}

.dropdown-filter li.selected {
  border: none
}