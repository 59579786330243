.create-page .buttons {
    text-align: center;
}

.create-page .btn-create {
    margin-top: 15px;
    padding: 0px 40px;
    background-color: #5f89ba;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    margin-left: 5%;
    cursor: pointer;
    height: 40px;
}
.create-page .btn-create:disabled {
    cursor: not-allowed;
}

.create-page .btn-cancel {
    margin-top: 15px;
    padding: 0px 40px;
    margin-left: 1%;
    border-radius: 5px;
    background-color: #ffffff !important;
    color: #5f89ba !important;
    border: solid 1px #5f89ba !important;
    height: 40px;
    font-size: 15px;
    line-height: 21.5px;
}

.create-page button:hover {
    opacity: 0.7;
    transition: opacity 0.5s;
}

.create-page .input-body {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.create-page .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.create-page .form-group label {
    margin-bottom: 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}

.create-page .form-group input[type="text"] {
    width: 100%;
    padding: 18px 8px;
    font-size: 14px;
    border: 1px solid #b4c7de;
    border-radius: 5px;
}
.boxRadio {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 35px;
}

.create-page .table-list-item {
    width: -webkit-fill-available;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 10px;
}

.create-page .table-list-item td,
.table-list-item th {
    padding: 5px;
}

.create-page .table-list-item th {
    background-color: #E4E5EE;
        border-bottom: 1px solid #5F89BA80;
        border-left: 0px;
        border-right: 0px;
        border-top: 1px solid #5F89BA80;
}

.create-page .table-list-item td {
    border-bottom: 1px solid #5F89BA80;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
}

.table-list-item thead td,
.table-list-item thead th {
    border-top: 1px solid #5F89BA80;
}

.create-page .input-table {
    padding: 20px;
}

.create-page .close-round {
    color: #FF0000;
    font-size: 20px;
}

.create-page .file-name-title {
    margin-bottom: 0px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    margin-inline: 3px;
}

.quotation .deadline fieldset{
    border: none;
}
  
.quotation .modal-body {
    width: 600px;
}

.quotation span {
    font-size: 14px;
}

.quotation .form-group textarea {
    padding: 8px 8px;
    font-size: 14px;
    border: 1px solid #b4c7de;
    border-radius: 4px;
}

.quotation .number-members{
    width: 48% !important;
}

.quotation .container-odc{
    margin-top: 0px !important;
    display: flex;
    justify-content: space-between;
}
.quotation .number-day{
    width: 48% !important;
}

.error-text {
    font-size: 14px;
}

.react-select__multi-value__label {
    font-size: 14px !important;
}

.deal-note_container {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}
.deal-note_container .item {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    
}
.deal-note_container .item-wrap {
    background-color: rgb(255, 255, 255);
    width: 100%;
    border: 0.8px solid rgb(95, 137, 186);
    padding: 10px;
    border-radius: 3px;
    max-width: 600px;
}
.deal-note_container .ck-content {
    padding: 10px;
}
.proposal-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}
.container-content:has(div.container-quotation) {
    background-color: rgb(246 247 250);
}