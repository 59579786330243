.btn-proposal {
    text-align: center;
}

.btn-proposal .button {
    width: 150px;
}

.create-proposal .btn-create, .btn-proposal .btn-create {
    margin: 15px 0px;
    padding: 0px 40px;
    background-color: #5f89ba;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    margin-left: 2%;
    cursor: pointer;
    height: 40px;
}

.btn-proposal .btn-edit {
    margin: 15px 0px;
    padding: 0px 40px;
    background-color: #EE8062;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    margin-left: 2%;
    cursor: pointer;
    height: 40px;
}

.btn-proposal .btn-cancel {
    margin: 15px 0px;
    padding: 0px 40px;
    margin-left: 2%;
    border-radius: 5px;
    background-color: #ffffff !important;
    color: #5f89ba !important;
    border: solid 1px #5f89ba !important;
    height: 40px;
}

.btn-proposal button:hover {
    opacity: 0.7;
    transition: opacity 0.5s;
}

.title-proposal {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
    display: inline-block;
    margin-top: 10px;
}

.create-proposal .input-body {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.create-proposal .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.create-proposal .form-group label {
    margin-bottom: 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}

.create-proposal .form-group input[type="text"] {
    width: 100%;
    padding: 18px 8px;
    font-size: 14px;
    border: 1px solid #b4c7de;
    border-radius: 5px;
}

.create-proposal textarea {
    width: 100%;
    font-size: 14px;
    border: 1.5px solid #b4c7de;
    border-radius: 5px;
    padding: 8px 8px;
}

.create-proposal textarea:disabled {
    color: hsl(0, 0%, 60%) !important;
  }

.create-proposal .form-group .input-disable {
    background-color: #fafafa !important;
}

.create-proposal select {
    border: 1px solid #b4c7de;
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    font-size: 18px;
}

.create-proposal .table-list-item {
    width: -webkit-fill-available;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 10px;
}

.boxRadio {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 35px;
}

.create-proposal .file-name-title {
    margin-bottom: 0px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    margin-inline: 3px;
}

.create-proposal .container-odc {
    display: flex;
    justify-content: space-between;
    margin-top: 0px !important;
}

.create-proposal .number-day, .create-proposal .number-members{
    width: 48% !important;
}

.box-action {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box-action .action-create {
    width: 136px;
    height: 30px;
    background: #EE8062;
    font-size: 14px;
    color: #ffff;
    border-radius: 5px;
}

.react-select__multi-value__label {
    font-size: 14px !important;
}
