.modal-right .form-group input[type="number"],  .modal-right .form-group input[type="date"]  {
    width: 100%;
    padding: 7px;
    font-size: 14px;
    border: 1px solid #b4c7de;
    border-radius: 5px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    height: 44px;
}
.css-e6wq8o-MuiInputBase-root-MuiOutlinedInput-root:hover {
    border-color: rgb(229, 231, 235) !important;
}
.modal-right .modal-body {
    padding-bottom: 50px;
}

.modal-right .form-group input[type="text"] {
    padding: 21px 11px;
}

.input-date .MuiInputBase-formControl {
    height: 44px;
}

.input-date .MuiInputBase-formControl .MuiInputBase-input {
    padding: 16.5px 11px;
}

.react-select__multi-value__label {
    font-size: 14px !important;
}
