.tab-switcher {
  width: 100%;
}

.bread-crumb-switch {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.bread-crumb-switch.active {
  font-weight: 700;
}

.tab-switcher__header {
  display: flex;
  align-items: center;
  width: 100%;
}

.mr-20 {
  margin-right: 20px;
}

.tab-switcher .activity {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  justify-content: space-between;
}

.activity__search {
  display: flex;
  align-items: center;
  width: 50%;
}

.activity__search .search-icon {
  position: relative;
  color: rgb(95, 137, 186);
  font-size: 1.3rem;
  cursor: pointer;
  left: -30px;
}

.activity__search .search {
  width: 100%;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  border: 0.8px solid rgb(95, 137, 186);
  border-radius: 2px;
  flex: 1;
}

.activity__create {
  margin-top: -10px;
  padding: 0px 30px;
  height: 35px;
}

.stage_history{
  border-top-width: 3px !important;
  border-radius: 5px !important;
}

.tab-switcher .item-wrap {
  background-color: rgb(255, 255, 255);
  width: 100%;
  border: 0.8px solid rgb(95, 137, 186);
  padding: 10px;
  margin-top: 20px;
  border-radius: 3px;
}

.type-task {
  display: flex;
  align-items: center;
  height: 14px;
  margin-top: 12px;
}

.type-task p {
  color: #33475B;
  font-size: 13px;
  margin-right: 13px;
}

.p-magrin-right {
  margin-right: 28px;
}

.show-contact {
  cursor: pointer;
  color: #0091AE;
  font-size: 10px;
  white-space: normal;
  margin-left: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.show-contact:hover{
  cursor: pointer;
  word-break: break-all;
}

.show-type {
  color: #0091AE;
  font-size: 14px;
  white-space: normal;
  margin-left: 10px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.show-deal {
  color: #0091AE;
  font-size: 14px;
  white-space: normal;
  margin-left: 10px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.show-order {
  color: #0091AE;
  font-size: 14px;
  white-space: normal;
  margin-left: 10px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comment-task .ck.ck-editor{
  max-width: none;
}

.comment-task .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused), .comment-task .ck-focused {
  border-color: #F6F7FA;
  background: #F6F7FA;
}

.comment-task .ck .ck-sticky-panel .ck-sticky-panel__content_sticky {
  position: static;
}

.comment-task .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,.comment-task .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  width: auto;
  background: #F6F7FA;
  margin-bottom: 0;
}

.comment-task .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  border-radius: 6px !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.comment-task .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,.comment-task .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  position: static;
}

.comment-task .ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset_all {
  background: #F6F7FA;
  border-radius: 6px !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.comment-task .modal-footer .buttons{
  border-top: none;
}

.comment-task .ck.ck-toolbar {
  border-bottom: none !important;
}

.comment-task .modal-footer{
  text-align: center;
}

.comment-task .btn-create{
  margin-top: 20px;
}

.content-log {
  width: 75%;
  overflow: hidden;
  word-wrap: initial;
  text-overflow: ellipsis;
  word-break: break-all;
}
