.MuiFormControlLabel-label {
  font-size: 14px !important;
  font-family: Inter !important;
}

.detail .btn-create {
  margin-top: 15px;
  padding: 0px 40px;
  background-color: #5f89ba;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  margin-left: 5%;
  cursor: pointer;
  height: 40px;
}
.form-checkbox_list {
  border-bottom: 1px solid #dddcdc !important;
  width: 100%;
  padding-bottom: 10px !important;
}
.form-exclude {
    padding: 20px 0;
}