.fields-search {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1%;
}

.filter .dropdown-unit .dropdown-menu {
  left: -100px;
}

.filter .dropdown-type .dropdown-menu {
  left: -250px;
}

.filter .dropdown-pic .dropdown-menu {
  left: -55px;
}

.filter .dropdown-object .dropdown-menu {
  left: -170px;
}

@media screen and (max-width: 1050px) {
  .header-items {
    display: block;
  }

  .filter-container {
    justify-content: flex-start;
  }

  .pagination-block {
    margin-right: auto;
  }

  .filter {
    padding: 0px 0px;
  }

  .filter .dropdown-stages .dropdown-menu {
    left: -16px;
  }

  .filter .dropdown-type .dropdown-menu {
    left: -100px;
  }

  .filter .dropdown-object .dropdown-menu {
    left: -16px;
  }
}