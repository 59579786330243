.btn-invoice {
    text-align: center;
}

.btn-invoice .button {
    width: 130px;
}

.invoice-amount .btn-create, .btn-invoice .btn-create {
    margin: 15px 0px;
    padding: 0px 40px;
    background-color: #5f89ba;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    margin-left: 2%;
    cursor: pointer;
    height: 40px;
}

.btn-invoice .btn-cancel {
    margin: 15px 0px;
    padding: 0px 40px;
    margin-left: 2%;
    border-radius: 5px;
    background-color: #ffffff !important;
    color: #5f89ba !important;
    border: solid 1px #5f89ba !important;
    height: 40px;
}

.btn-invoice button:hover {
    opacity: 0.7;
    transition: opacity 0.5s;
}

.invoice-amount .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.invoice-amount .form-group label {
    margin-bottom: 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}

.invoice-amount .form-group input[type="text"] {
    width: 100%;
    padding: 18px 8px;
    font-size: 14px;
    border: 1px solid #b4c7de;
    border-radius: 5px;
    color: #646363 !important;
}

.invoice-amount .form-group input:disabled {
    background-color: #C9C9CA !important;
}
