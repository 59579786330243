.create-page-order .buttons {
    text-align: center;
}

.create-page-order button:hover {
    opacity: 0.7;
    transition: opacity 0.5s;
}

.create-page-order .btn-create {
    margin-top: 15px;
    padding: 0px 40px;
    background-color: #5f89ba;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    margin-left: 20px;
    cursor: pointer;
    height: 40px;
    white-space: nowrap;
}

.create-page-order .btn-invoice{
    margin-top: 15px;
    padding: 0px 40px;
    background-color: #EE8062;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    height: 40px;
    white-space: nowrap;
}

.create-page-order .btn-cancel {
    margin-top: 15px;
    padding: 0px 40px;
    margin-left: 1%;
    border-radius: 5px;
    background-color: #ffffff !important;
    color: #5f89ba !important;
    border: solid 1px #5f89ba !important;
    height: 40px;
}

.create-page-order .input-body {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.create-page-order .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.create-page-order .form-group label {
    margin-bottom: 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}

.create-page-order .form-group input[type="text"] {
    width: 100%;
    padding: 18px;
    font-size: 18px;
    border: 1px solid #b4c7de;
    border-radius: 5px;
}

.create-page-order select {
    border: 1px solid #b4c7de;
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    font-size: 18px;
}

.create-page-order .table-list-item {
    width: -webkit-fill-available;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 10px;
}

.create-page-order .table-list-item td,
.table-list-item th {
    padding: 5px;
}

.create-page-order .table-list-item th {
    background-color: #E4E5EE;
        border-bottom: 1px solid #5F89BA80;
        border-left: 0px;
        border-right: 0px;
        border-top: 1px solid #5F89BA80;
}

.create-page-order .table-list-item td {
    border-bottom: 1px solid #5F89BA80;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
}

.table-list-item thead td,
.table-list-item thead th {
    border-top: 1px solid #5F89BA80;
}

.create-page-order .input-table {
    padding: 20px;
}

.create-page-order .close-round {
    color: #FF0000;
}

.create-page-order .file-name-title {
    margin-bottom: 0px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin-inline: 3px;
}

.create-page-order .container-note {
    border: 1px solid #5F89BA;
    border-radius: 4px;
    height: 30px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #2E89FF;
}

.create-page-order .container-note:hover {
    opacity: 0.5;
    transition: ease-in 0.1s;
}

.create-page-order .input-currency {
    font-size: 14px;
    border: 1px solid #5F89BA;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    padding: 0 4px;
}

.create-page-order .input-has-currency{
    border-radius: 5px 0 0 5px !important;
}

.create-page-order .input-tax{
    max-width: 300px;   
    min-width: 100px;   
}

.create-page-order .table-list-item th {
    white-space: nowrap;
}

td .MuiTextField-root{
    width: 100%;
}