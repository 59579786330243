.sign-in-google-button {
    background-color: #fff !important;
    font-weight: bold;




}

.sign-in-google-button span, a {
    color: #3d3d3d;
    text-transform: none;

}

.google-icon {
    width: 18px;
    height: 18px;

    margin-right: 8px;
}