.tabs.tab_log {
  padding-bottom: 0;
  width: 100%;
}

.tab_log {
  border-bottom: 1px solid #d6d6d6;
}
.tab_log > div {
  padding: 10px;
}

.tab_log:hover {
  color: #5f89ba;
  border-color: #d6d6d6;
  border-bottom-width: 1px;
}

.container-content:has(div.container-order_log) {
  background-color: rgb(246 247 250);
}
.container-order_log .search-log {
  margin-top: 20px;
  background-color: #fff;
  padding: 10px 50px 10px;
}

.container-order_log .table-log {
  margin-top: 20px;
  background-color: #fff;
  padding: 0 0 10px 0;
}

.search-log .input-search {
  height: 38px;
  white-space: nowrap;
  width: 100%;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 3px 3px 3px 3px;
  padding: 7px;
}
.search-log .input-search:focus {
  outline: unset;
  border: 2px solid #2684ff;
}
.button-search {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.table-invoice,
.table-revenue {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}
.table-revenue th,
.table-revenue td {
  border: 1px solid black;
  padding: 8px;
  font-size: 16px;
}
.table-revenue th {
  background-color: #f2f2f2;
}
.positive {
  color: blue;
}
.negative {
  color: red;
}
.invoice-change tbody {
  text-align: center;
}