.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle-switch:not(.disabled) {
  cursor: pointer;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-handle {
  -webkit-transform: translateX(45px);
          transform: translateX(45px);
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 3px #5F89BA;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 3px #5F89BA;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-background {
  background-color: #5F89BA;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch:before {
  content: "On";
  color: #5F89BA;
  right: -15px;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-background .toggle-switch-handle {
  -webkit-transform: translateX(18px);
          transform: translateX(18px);
}

.toggle-switch input[type="checkbox"]:checked.success + .toggle-switch-handle {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 3px #05c46b;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 3px #05c46b;
}

.toggle-switch input[type="checkbox"]:checked.success + .toggle-switch-background {
  background-color: #05c46b;
}

.toggle-switch input[type="checkbox"]:checked.success + .toggle-switch:before {
  color: #05c46b;
}

.toggle-switch .toggle-switch-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #80868B;
  border-radius: 20px;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.toggle-switch .toggle-switch-background .toggle-switch-handle {
  position: absolute;
  top: 1.5px;
  left: 2.5px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.toggle-switch::before {
  content: "";
  position: absolute;
  top: -25px;
  right: -35px;
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
  text-shadow: 1px 1px #fff;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
