.modal-center .modal {
  position: fixed;
  background-color: #FFFF;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 0px #000000;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  display: flex;
  border-radius: 8px;
  flex-direction: column;
  height: auto;
  min-width: 420px;
}

.modal-center .modal.open {
  left: 0;
}

.modal-center .modal-body {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  max-height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.modal-center .modal-header {
  width: 100%;
  padding: 20px;
  color: #474747;
  text-align: left;
  border-bottom: 1px solid rgba(95, 137, 186, 0.5);
  font-size: 22px;
  font-weight: 700;
}

.modal-center .modal-footer {
  margin-top: auto;
}

.modal-center .form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modal-center .form-group label {
  margin-bottom: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.modal-center .form-group input[type="text"] {
  width: 100%;
  padding: 18px;
  font-size: 18px;
  border: 1px solid #b4c7de;
  border-radius: 5px;
}

.modal-center select {
  border: 1px solid #b4c7de;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  font-size: 18px;
}

.modal-center .buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin-bottom: 20px;
  border-top: none;
}

.modal-center .buttons button {
  padding: 10px 20px;
  background-color: #5f89ba;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  margin-left: 10px;
  cursor: pointer;
}

.modal-center .btn-cancel {
  background-color: #ffffff !important;
  color: #5f89ba !important;
  border: solid 1px #5f89ba !important;
}

.modal-center .buttons button:hover {
  opacity: 0.8;
}

.modal-center.buttons {
  border-top: solid #b4c7de 1px;
}

.modal-center .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.modal-center input:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.modal-center .modal-open {
  overflow: hidden;
}

.modal-center .form-group {
  margin-bottom: 10px !important;
}

.modal-center .error-text {
  margin-top: 10px;
}

.modal-center .css-1u9des2-indicatorSeparator {
  display: none !important;
}

@media (max-width: 767px) {
  .modal-center .modal-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-center .modal {
    width: 90%;
    max-width: 400px;
  }

  .modal-center .modal-body {
    width: 100%;
  }
}
@media (max-width: 425px) {
  .modal-center .modal {
    right: auto !important;
  }

}

.react-select__menu {
  position: fixed;
  z-index: 99999;
}

.confirm-message {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 1em;
}