.country-flags {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border: 1px solid var(--border-color);
}
.country-flags.item {
    width: 20px;
    height: 20px;
}
.rounded-circle {
    border-radius: 50% !important;
}

.button-choice {
   background-color: rgb(95 137 186);
   color: #fff;
   
}
.language-item {
    position: absolute;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-bg-opacity: 1;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    border-radius: 0.375rem;
    top: 55px;
    width: auto;
    padding: .5rem 0;
    min-width: 150px;
    left: auto;
    z-index: 999;
}
.language-item button {
    display: flex;
    justify-content: space-between;
    padding: .25rem 1.5rem;
}
.icon-arrow:hover{
    color: #fff !important;
}