.tag-contact,
.tag-deal,
.tag-orderDate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: -webkit-fill-available;
}

.header-items-order-chip {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    max-height: 40px;
}

.header-items-order-chip>div:first-child {
    display: flex;
    flex-grow: 0;
    width: 50%;
    flex-flow: wrap;
}

.filter-container-chip .filter .daterangepicker,
.filter-container-chip .filter .dropdown-menu{
    left: auto !important;
    right: 0;
    flex-grow: 0;
}