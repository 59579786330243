.modal-container .link-button.selected-import {
    color: #5179A7;
    cursor: default;
    font-weight: 600;
}

.modal-container .link-button {
    cursor: pointer;
}

.input-date .MuiInputBase-formControl {
    height: 44px;
}

.input-date .MuiInputBase-formControl .MuiInputBase-input {
    padding: 16.5px 11px;
}

.react-select__multi-value__label {
    font-size: 14px !important;
}
