.modal-detail-contact .modal-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.modal-detail-contact .modal-container .modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  margin-left: auto;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  overflow: auto;
  -webkit-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}

.modal-detail-contact .modal-container .modal.open {
  right: 0;
}

.modal-detail-contact .modal-container .modal .page-header {
  padding: 18px 24px 10px;
}

.modal-detail-contact .modal-container .modal .page-header h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.modal-detail-contact .modal-container .modal .modal-header {
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#00BDA5), to(#00A4BD));
  background: linear-gradient(to right, #00BDA5, #00A4BD);
  padding: 20px;
  color: #ffffff;
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  height: 73px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-detail-contact .modal-container .modal .modal-header .title {
  display: inline-block;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
  margin: 0px 10px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: start;
}

.modal-detail-contact .modal-container .modal .modal-body {
  width: 100vw;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-end;
}

.modal-detail-contact .modal-container .modal .modal-body .preview-table-wrapper.shrink-out {
  max-width: calc(100vw - 120px);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.modal-detail-contact .modal-container .modal .modal-body .preview-table-wrapper.shrink-in {
  max-width: calc(100vw - 500px);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper {
  width: 500px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-y: hidden;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-left: solid #b4c7de 1px;
  background-color: #ffffff;
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper .form {
  width: 100%;
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper .form .form-groups {
  padding: 20px;
  position: relative;
}

@media (min-height: 300px) {
  .modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper .form .form-groups {
    overflow-y: auto;
    max-height: calc(100vh - 73px - 65.5px - 20px);
  }
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper .actions {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 10px;
  margin-bottom: 20px;
  border-top: solid #b4c7de 1px;
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper .actions button {
  padding: 10px 20px;
  background-color: #5f89ba;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper .actions button.btn-cancel {
  background-color: #ffffff !important;
  color: #5f89ba !important;
  border: solid 1px #5f89ba !important;
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper.shrink-in {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  width: 120px;
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper.shrink-in .modal-header .title {
  display: none;
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper.shrink-in .form-groups {
  display: none;
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper.shrink-in .actions {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper.shrink-in .actions button {
  margin-left: 0px;
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper.shrink-in .actions button:last-child {
  margin-top: 10px;
}

.modal-detail-contact .modal-container .modal .modal-body .import-form-wrapper.shrink-out {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  width: 500px;
}

.modal-detail-contact .modal-container .modal .modal-body .form-groups .switch {
  margin-top: -5px;
  padding-bottom: 15px;
  position: sticky;
  top: 0px;
  background-color: white;
  border-bottom: 1px solid #dddcdc;
  z-index: 10;
}

.modal-detail-contact .modal-container .modal .modal-body .form-groups .switch + div {
  margin-top: 20px;
}

.modal-detail-contact .modal-container .modal .modal-body .form-group {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 10px;
}

.modal-detail-contact .modal-container .modal .modal-body .form-group label {
  margin-bottom: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.modal-detail-contact .modal-container .modal .modal-body .form-group input[type="text"] {
  width: 100%;
  padding: 18px;
  font-size: 14px;
  border: 1px solid #b4c7de;
  border-radius: 5px;
}

.modal-detail-contact .modal-container .modal .modal-body .form-control-contacts-fs {
  font-size: 16px !important;
}

.modal-detail-contact .modal-container .modal .modal-body .form-group input[type="text"]:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.modal-detail-contact .modal-container .modal .modal-body .form-group select {
  border: 1px solid #b4c7de;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  font-size: 18px;
}

.modal-detail-contact .modal-container .modal .modal-body .error-text {
  margin-top: 5px;
  color: red;
  /*text-transform: lowercase;*/
}

.modal-detail-contact .modal-container .modal .modal-body .error-text::first-letter {
  text-transform: uppercase;
}

.modal-detail-contact .modal-container .modal .modal-body .result {
  width: 100%;
  min-height: 150px;
  border: 1px solid #b4c7de;
  margin-top: 20px;
  border-radius: 4px;
  font-size: 14px;
  padding: 20px;
}

.modal-detail-contact .modal-container .modal .modal-body .result > div:not(:first-child) {
  margin-top: 5px;
}

.modal-detail-contact .modal-container .modal .modal-body .result .uncompleted-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.modal-detail-contact .modal-container .modal .modal-body .result .uncompleted-wrapper>*:not(:last-child) {
  margin-right: 10px;
}

.modal-detail-contact .modal-container .modal .modal-body .result .uncompleted-wrapper .MuiLoadingButton-root {
  background-color: rgb(255, 255, 255);
  color: rgb(95, 137, 186) !important;
  padding: 6px 15px;
  border-radius: 5px;
  border-width: 1px;
  transition: all 0.3s ease 0s;
  opacity: 1;
  font-family: 'Inter';
  text-transform: initial !important;
}

.modal-detail-contact .modal-container .modal .modal-body .result * {
  word-break: break-all;
  overflow-wrap: break-word;
}

.modal-detail-contact .modal-open {
  overflow: hidden;
}

.modal-detail-contact .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
  .modal-detail-contact .modal-detail-contact .modal-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .modal-detail-contact .modal-detail-contact .modal {
    width: 90%;
    max-width: 400px;
  }

  .modal-detail-contact .modal-detail-contact .modal-body {
    width: 100%;
  }
}

.modal-detail-contact .preview-import-table .MuiTableContainer-root {
  max-height: calc(100vh - 104px);
}

.modal-detail-contact .preview-import-table table .thead th {
  background-color: #DBDADA;
  border-top: 1px solid rgba(95, 137, 186, 0.5);
  border-bottom: 1px solid rgba(95, 137, 186, 0.5);
  top: 75px;
  vertical-align: middle;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  width: auto;
}

.modal-detail-contact .preview-import-table table .thead th:not(.numerical-order) div {
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-detail-contact .preview-import-table table td {
  border-bottom: 1px solid rgba(95, 137, 186, 0.5);
  vertical-align: middle;
}

.modal-detail-contact .preview-import-table table tr:has(+ .row-error) td, .modal-detail-contact .preview-import-table table .row-error td {
  border-bottom: 1px solid red;
}

.modal-detail-contact .preview-import-table table .row-error {
  background-color: #ffebee;
}

.modal-detail-contact .preview-import-table table .row-error .MuiSvgIcon-colorError:hover {
  color: #d32f2f !important;
}

.modal-detail-contact .preview-import-table table tr:not(.row-error):has(+ .row-warning) td, .modal-detail-contact .preview-import-table table .row-warning td {
  border-bottom: 1px solid #ffd54f;
}

.modal-detail-contact .preview-import-table table .row-warning {
  background-color: #fffde7;
}

.modal-detail-contact .preview-import-table table .row-warning .MuiSvgIcon-colorWarning, .modal-detail-contact .preview-import-table table .row-warning .MuiSvgIcon-colorWarning:hover {
  color: #fbc02d !important;
}

.modal-detail-contact .preview-import-table table tbody td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.modal-detail-contact .preview-import-table table .MuiBox-root {
  width: 120px !important;
}

.modal-detail-contact .preview-import-table table .MuiSelect-select {
  max-height: 38px;
  padding: 9.5px 14px;
}

.modal-detail-contact .text-no-data {
  padding: 30px 20px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.modal-detail-contact .preview-import-table table.skeleton-table thead th {
  border-top: 1px solid rgba(95, 137, 186, 0.5);
}

.modal-detail-contact .preview-import-table table.skeleton-table td, .modal-detail-contact .preview-import-table table.skeleton-table th {
  border-bottom: 1px solid rgba(95, 137, 186, 0.5);
}

.modal-detail-contact .preview-import-table table .MuiSkeleton-root {
  width: 100%;
  height: 40px;
  background-color: #eeeeee;
}

.modal-detail-contact .modal-container .link-button {
  cursor: pointer;
}

.modal-detail-contact .modal-container .link-button.selected-import {
  color: #5179A7;
  cursor: default;
  font-weight: 600;
}

.contact-point-remove-button {
  position: absolute;
  right: 35px !important;
  font-size: 24px;
  color: #33475B;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: -16px;
    z-index: 3;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    max-height: 250px;
  }

.contact-point-dropdown {
  margin-top: 10px;
}

.contact-point-dropdown .dropdown-checkbox-contact-point input {
    margin-right: 5px;
}

.contact-point-dropdown label {
    position: relative;
    bottom: 2px;
    color: #474747 !important;  
    font-size: 14px;
}

.contact-point-dropdown .dropdown-checkbox-contact-point input:checked + label {
    color: #5F89BA !important;
}

.contact-point .dropdown-menu{
  left: -37px;
}

.list-item .dropdown-menu {
  left: -37px;
}

.dropdown-checkbox-contact-point {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 15px;
}

.dropdown-checkbox-contact-point label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-checkbox-contact-point label:hover {
  cursor: pointer;
  white-space: normal;
  word-break: break-all;
}