.drag-and-drop {
  display: flex;
  flex-wrap: wrap-reverse;
  border: 2px dashed #ccc;
  background-color: #fafafa;
  color: #333;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 2rem;
  transition: all 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
}

.label-select-file {
  background-color: #5179A7;
  color: #FFFFFF;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  word-break: break-all;
  overflow-wrap: break-word;
  margin-bottom: 0px !important;
}

.show-filename {
  padding: 10px 10px;
}

.drag-and-drop--active {
  border-color: #333;
  background-color: #f4f4f4;
}

.drag-and-drop span {
  color: #8d8d8d;
}

.remove-image-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  background-color: #000000;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.drag-and-drop__image-container {
  position: relative;
}

.drag-and-drop__image-container img {
  max-width: 100%;
  max-height: 100%;
}

.remove-image-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 50%;
  padding: 5px;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
}
