.contact {
    font-size: 14px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
  
.menu-contact:hover{
    cursor: pointer;
    word-break: break-all;
}