.btnClose:hover {
    color: #474747 !important;
    transition: ease-in 0.2s;
}

.modalTask .modal-body {
    width: auto;
}

.modalTask .modal-body .taskName {
    padding: 15px;
    height: 40px;
}

.modalTask .modal-body .taskName:focus {
    outline: none;
}

.modal-center .modal-body,
.modal-center .modalTask .modal-body {
    width: 100% !important;
}

.modal-center .modalTask .modal-body>* {
    text-align: left !important;
}

.modalTask .modal-body .container-option {
    padding: 20px 30px 0px;
    justify-content: space-between;
    width: 100%;
    color: #33475B;
}

.modalTask .modal-body .container-option .deadline {
    align-items: center;
}

.modalTask .modal-body .container-option .assigned {
    margin-top: 5px;
}

.modalTask .modal-body .container-time {
    align-items: center;
}

.ck.ck-editor {
    max-width: 650px;
}

.modalTask .ck.ck-editor {
    display: flex;
    flex-direction: column-reverse;
}

.modalTask .ck.ck-editor__main>.ck-editor__editable {
    border-top: none;
    height: 186px;
    padding-inline: 16px;
}

@media (max-width: 767px) {
    .modalTask .modal-body .container-option {
        text-align: right;
        flex-direction: column-reverse;
    }

    #time-label {
        width: 75px;
    }

    .modalTask .modal-body .container-time {
        margin-bottom: 10px;
    }
}

.task-ckeditor .ck-rounded-corners .ck.ck-editor__main .ck-editor__editable,
.ck.ck-editor__main .ck-editor__editable.ck-rounded-corners {
    width: 635px;
    margin-bottom: 38px;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-top: 1px solid var(--ck-color-toolbar-border) !important;
    width: 100%;
    right: 0px;
    bottom: 59px;
}